.authorisationContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background-position: center;
    background-size: cover;

    .fadeOutContainer {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        background-color: rgba(0, 0, 0, 0.6);

        .formContainer {
            width: 50%;
            height: 100%;
            display: flex;

            .formBlock {
                width: 100%;
                max-width: 370px;
                height: auto;
                box-sizing: border-box;
                margin: auto 0px;
                padding: 50px 30px 10px 30px;
                display: flex;
                border-radius: 15px;
                flex-direction: column;
                background-color: #ffffff;


                img {
                    width: 60%;
                    max-width: 160px;
                    margin: 0 auto 10px auto;
                    display: flex;
                }

                p {
                    margin-bottom: 50px;
                    text-align: center;
                    color: #444444;
                    font-size: 13px;
                }
            }
        }

        .advertContainer {
            width: 50%;
            height: 100%;
            display: flex;
        }

        @media (max-width: 1100px) {

            .advertContainer {
                display: none;
            }

            .formContainer {
                width: 100%;

                .formBlock {
                    width: calc(100% - 40px);
                    max-width: 370px;
                    margin: auto;
                }
            }

        }
    }
}



