.filterTable__TableContainerPages{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin: 10px 0 30px 0px;
    align-items: baseline;

    span{
        width: auto;
        height: auto;
        padding: 5px;
        margin-right: 3px;
        color: rgba(0,0,0,.85);
        background: #fff;
    }

    button{
        width: auto;
        height: auto;
        padding: 11px 15px;
        margin-right: 5px;
        border-radius: 5px;
        color: #000;
        background: #f8f8f8;
        border: 1px solid #f8f8f8;
        cursor: pointer;
        font-size: 15px;
        text-decoration: none;
        -webkit-appearance: none;

        &:hover{
            color: #fff;
            background-color: #e16449;
            border-color: #e16449;
        }

        &.active{
            border: 0;
            color: #fff;
            background-color: #e16449;
            border-color: #e16449;
            cursor: not-allowed;
        }
    }

    select{
        display: flex;
        margin-left: auto;
        //-webkit-appearance: none;
    }
}