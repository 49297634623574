.filterTable__MainFiltersContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-bottom: 20px;

    .filtersContainer {
        display: flex;
        width: auto;
        flex-direction: row;
    }

    button {
        width: 40px;
        height: 40px;
        margin: auto 0;
        border-radius: 20px;
        border: 0;
        background-color: #eeeeee;
        display: flex;
        color: #000;

        &.active {
            color: #e16449;
        }

        &:hover {
            background-color: #e16449;
            color: #fff !important;
            cursor: pointer;
        }

        svg {
            width: 70%;
            display: flex;
            margin: auto;
        }
    }
}