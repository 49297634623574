.loadingContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: row;

    /* HTML: <div class="loader"></div> */
    .loader {
        width: 65px;
        height: 65px;
        aspect-ratio: 1;
        position: relative;
        display: flex;
        margin: auto;
    }
    .loader:before,
    .loader:after {
        content: "";
        position: absolute;
        border-radius: 50px;
        box-shadow: 0 0 0 3px inset #000;
        animation: l4 2.5s infinite;
    }
    .loader:after {
        animation-delay: -1.25s;
    }

    @keyframes l4 {
        0% {
            inset: 0 35px 35px 0;
        }
        12.5% {
            inset: 0 35px 0 0;
        }
        25% {
            inset: 35px 35px 0 0;
        }
        37.5% {
            inset: 35px 0 0 0;
        }
        50% {
            inset: 35px 0 0 35px;
        }
        62.5% {
            inset: 0 0 0 35px;
        }
        75% {
            inset: 0 0 35px 35px;
        }
        87.5% {
            inset: 0 0 35px 0;
        }
        100% {
            inset: 0 35px 35px 0;
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}