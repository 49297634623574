.app-container {
    max-width: 1100px;
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    margin: 0 auto;
    box-sizing: border-box;
    flex-direction: column;

    .menu {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 20px 30px;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        .logo-container {
            width: auto;
            height: auto;
            display: flex;

            a > img{
                width: 120px;
            }
        }

        .menu-items-container {
            width: auto;
            display: flex;
            flex-direction: row;

            a {
                width: auto;
                height: auto;
                display: flex;
                padding: 5px 13px;
                margin: 0 5px;
                color: #000;
                border-radius: 3px;
                font-style: normal;
                text-decoration: none;

                &:hover,
                &.active{
                    background-color: #EFF3FA;
                }
            }
        }

        .account-button-container {

        }

        .menu-button {
            width: auto;
            height: auto;
            display: none;

            button {
                width: 35px;
                height: auto;
                display: flex;
                padding: 5px 5px;
                color: #000;
                border-radius: 3px;
                font-style: normal;
                text-decoration: none;
                border: 0px;
                background: none;
                cursor: pointer;
                &:hover,
                &.active{
                    background-color: #EFF3FA;
                }

                svg {

                }
            }
        }

        @media (max-width: 650px) {

            .menu-button{
                display: flex;
            }
            .menu-items-container {
                display: none;
            }

        }
    }
}

.app-overlay-menu-container {
    display: none;
}

@media (max-width: 650px) {

    .app-overlay-menu-container {
        width: 100vw;
        height: auto;
        display: none;
        flex-direction: column;
        margin-top: 75px;
        position: fixed;
        top: 0;
        background: #fff;

        &.open {
            display: flex;
        }

        .menu-items-container {
            width: 100%;
            padding: 20px 30px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            a {
                width: 100%;
                height: auto;
                display: flex;
                padding: 5px 13px;
                margin-bottom: 10px;
                color: #000;
                border-radius: 3px;
                font-style: normal;
                text-decoration: none;
                box-sizing: border-box;

                &:hover,
                &.active{
                    background-color: #EFF3FA;
                }
            }
        }

    }

}

.app-container > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    box-sizing: border-box;
    /*background-color: #EFF3FA;*/

    .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
    }

    .blocks-row {
        width: calc(100% + 20px);
        left: -20px;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        position: relative;

        .half-block {
            width: 50%;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
        }

        @media (max-width: 650px) {

            & {
                flex-direction: column;
            }

            .half-block {
                width: calc(100% - 20px);
                margin-bottom: 20px;
            }
        }
    }

    .cards {
        width: calc(100% + 20px);
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        left: -20px;
        position: relative;

        .card {
            width: calc(50%);
            height: auto;
            display: flex;
            padding: 15px;
            margin-left: 20px;
            flex-direction: column;
            box-sizing: border-box;
            background-color: rgb(240, 240, 240);
            border-radius: 15px;

            .card-header-block {
                display: flex;
                flex-direction: row;

                .card-header-content {
                    display: flex;
                    flex: 1;
                }
                .card-header-buttons {

                    button {
                        width: 30px;
                        height: 30px;
                        padding: 5px;
                        color: #888888;
                        border-radius: 5px;
                        border: 0px;
                        cursor: pointer;

                        &:hover {
                            color: #000;
                            background: #e6e6e6;
                        }
                    }
                }
            }


            h1 {
                font-size: 21px;
                font-weight: 500;
            }

            table {
                border-spacing: 0px;
                tr {
                    td:first-child {
                        color: #888888;
                        width: auto;
                    }
                    td:last-child {
                        color: #000000;
                        width: 60%;
                    }
                }
            }

            .imagesList {
                width: 100%;
                margin: 10px 0;
                display: flex;
                flex-direction: row;
                align-items: start;
                justify-content: flex-start;
                flex-wrap: wrap;

                img {
                    width: calc(33% - 10px);
                    height: auto;
                    margin: 5px 5px;
                }
            }
        }

        @media (max-width: 650px) {

            &{
                flex-direction: column;
            }

            .card {
                width: calc(100% - 20px);
                margin-bottom: 20px;
            }

            .half-block {
                width: calc(100% - 20px);
                margin-bottom: 20px;
            }
        }
    }

    .new-card {
        width: 100%;
        height: auto;
        padding: 30px;
        display: flex;
        items-align: flex-start;
        background: #F5F5F7;
        box-sizing: border-box;
        border-radius: 15px;
        color: #1d1d1f;
        flex-direction: column;

        .card-text-row {
            width: calc(100% + 20px);
            left: -20px;
            display: flex;
            flex-direction: row;
            box-sizing: border-box;
            position: relative;

            .card-text-block {
                padding-bottom: 20px;
                display: flex;
                height: auto;
                flex-direction: column;
                margin-left: 20px;

                &.size--1-2 {
                    width: 50%;
                }

                &.size--1-3 {
                    width: 33.33%
                }

                &.size--1-4 {
                    width: 25%;
                }

                span {
                    width: 100%;
                    height: auto;
                    color: #6e6e73;
                }

                p {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                }

                @media (max-width: 750px) {

                    &.size--1-2 {
                        width: 100%;
                    }

                    &.size--1-3 {
                        width: 100%;
                    }

                    &.size--1-4 {
                        width: 100%;
                    }
                }
            }

            @media (max-width: 750px) {
                &{
                    flex-direction: column;
                }
            }
        }
    }

    table.meterReadingsTable {
        width: 100%;
        max-width: 400px;
        border-collapse: collapse;

        thead > tr > td {
            position: relative;
            font-weight: 600;
            border-bottom: 1px solid #f2f2f2;
            padding: 15px 20px 15px 20px;
            background-color: #f8f8f8;
            color: #000;
            font-size: 15px;
            text-align: left;
        }

        thead > tr > td::before{
            position: absolute;
            top: 50%;
            inset-inline-end: 0;
            width: 1px;
            height: 1.6em;
            background-color: #eeeeee;
            transform: translateY(-50%);
            transition: background-color;
            content: "";
        }

        thead > tr > td:last-child::before{
            display: none;
        }

        tbody > tr > td{
            border-bottom: 1px solid #f2f2f2;
            padding: 15px 20px 15px 20px;
            color: #666666;
            font-size: 15px;
            text-align: left;

            button {
                width: 30px;
                height: 30px;
                margin: auto 0;
                border-radius: 20px;
                border: 0;
                background-color: #eeeeee;
                display: flex;
                color: #000;

                &:hover {
                    background-color: #e16449;
                    color: #fff !important;
                    cursor: pointer;
                }

                svg {
                    width: 70%;
                    display: flex;
                    margin: auto;
                }
            }
        }
    }

    table.ownersTable {
        width: 100%;
        border-collapse: collapse;

        thead > tr > td {
            position: relative;
            font-weight: 600;
            border-bottom: 1px solid #f2f2f2;
            padding: 15px 20px 15px 20px;
            background-color: #f8f8f8;
            color: #000;
            font-size: 15px;
            text-align: left;
        }

        thead > tr > td::before{
            position: absolute;
            top: 50%;
            inset-inline-end: 0;
            width: 1px;
            height: 1.6em;
            background-color: #eeeeee;
            transform: translateY(-50%);
            transition: background-color;
            content: "";
        }

        thead > tr > td:last-child::before{
            display: none;
        }

        tbody > tr {

            & > td{
                border-bottom: 1px solid #f2f2f2;
                padding: 15px 20px 15px 20px;
                color: #666666;
                font-size: 15px;
                text-align: left;

                button {
                    width: 40px;
                    height: 40px;
                    margin: auto 0;
                    border-radius: 20px;
                    border: 0;
                    background-color: #eeeeee;
                    display: flex;
                    color: #000;

                    &:hover {
                        background-color: #e16449;
                        color: #fff !important;
                        cursor: pointer;
                    }

                    svg {
                        width: 70%;
                        display: flex;
                        margin: auto;
                    }
                }
            }

            &.member > td {
                font-weight: 700;
//                 color: #eee;
            }

        }
    }

    @media screen and (max-width: 650px) {

        table caption {
            font-size: 1.3em;
        }

        table.ownersTable {
            width: 100%;
            border: 0;

            colgroup {
                display: none;
            }

            thead {
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
                display: none;
            }

            tbody {
                tr {
                    width: 100%;
                    border-bottom: 3px solid #eeeeee;
                    display: block;
                    margin-bottom: .625em;

                    td {
                        border-bottom: 1px solid #eeeeee;
                        display: block;
                        //font-size: .8em;
                        text-align: right;
                        padding-left: 0;
                        padding-right: 0;
                        font-weight: 600;

                        button {
                            margin-left: auto;
                        }
                    }

                    td::before {
                        content: attr(data-label);
                        float: left;
                        font-weight: 500;
                        text-transform: uppercase;
                        text-align: left;
                    }

                    td:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}


.photoModal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eeeeee;

    .close {
        position: fixed;
        top: 15px;
        right: 15px;
        width: 40px;
        height: 40px;
        z-index: 1001;
        display: flex;

        button{
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            border-radius: 5px;
            background-color: #eeeeee;
            color: #000;
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }
}

.uploadFileButton {
    width: 100%;
    margin: 20px 0;
    background-color: rgba(225, 100, 73, 1);
    border-radius: 5px;
    padding: 10px;
    border: 0;
    color: #fff;
}

.uploadFileButton.load{
    animation: l5 2.5s infinite;
}

@keyframes l5 {
        0% {
            background-color: rgba(225, 100, 73, 1);
        }
        50% {
            background-color: rgba(225, 100, 73, 0.6);
        }
        100% {
            background-color: rgba(225, 100, 73, 1);
        }
    }