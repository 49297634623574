.filterTable__TableContainer{
    flex-direction: column;
    width: 100%;
    height: auto;

    table{
        border-collapse: collapse;
        width: 100%;

        thead > tr {

        }

        thead > tr > td{
            position: relative;
            font-weight: 600;
            border-bottom: 1px solid #f2f2f2;
            padding: 15px 20px 15px 20px;
            background-color: #f8f8f8;
            color: #000;
            font-size: 15px;
            text-align: left;

            .filterTable__theadCellContainer {
                display: flex;
                flex-direction: row;
                width: 100%;

                .filterTable__theadCellName {
                    display: flex;
                    flex-direction: column;

                    span {
                        display: flex;
                        margin: auto 0;
                        color: #666666;

                        &:first-child {
                            color: #000000;
                        }
                    }
                }

                .filterTable__theadCellButton {
                    display: flex;
                    margin-left: auto;

                    button {
                        width: 30px;
                        height: 30px;
                        margin: auto 0;
                        border-radius: 15px;
                        border: 0;
                        background-color: #eeeeee;
                        display: flex;
                        color: #000;

                        &:hover {
                            background-color: #e16449;
                            color: #fff;
                            cursor: pointer;
                        }
                    }
                }
            }

            &:first-child{
                border-top-left-radius: 10px;
            }
            &:last-child{
                border-top-right-radius: 10px;
            }
        }

        thead > tr > td::before{
            position: absolute;
            top: 50%;
            inset-inline-end: 0;
            width: 1px;
            height: 1.6em;
            background-color: #eeeeee;
            transform: translateY(-50%);
            transition: background-color;
            content: "";
        }

        thead > tr > td:last-child::before{
            display: none;
        }

        tbody > tr > td{
            border-bottom: 1px solid #f2f2f2;
            padding: 15px 20px 15px 20px;
            color: #666666;
            font-size: 15px;
            text-align: left;

            a{
                display: inline-block;
                margin-right: 10px;
                white-space: normal;
                font-weight: 500;
                text-decoration: none;
                color: #e16449;
                border-bottom: 1px solid #f6ecea;

                &.svg{
                    width: 19px;
                    height: 19px;
                }

                &:hover{
                    color: #000;
                    border-bottom: 1px solid #000;
                    box-sizing: border-box;
                }
            }
        }
    }

    @media screen and (max-width: 600px) {

        table > colgroup {
            display: none;
        }
        table {
            width: 100%;
            border: 0;
        }

        table caption {
            font-size: 1.3em;
        }

        table > thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            display: none;
        }

        table > tbody > tr {
            width: 100%;
            border-bottom: 3px solid #eeeeee;
            display: block;
            margin-bottom: .625em;
        }

        table > tbody > tr > td {
            border-bottom: 1px solid #eeeeee;
            display: block;
            font-size: .8em;
            text-align: right;
        }

        table > tbody > tr > td::before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }

        table > tbody > tr > td:last-child {
            border-bottom: 0;
        }
    }
}