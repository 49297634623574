.filterTable__Container {
    flex-direction: column;
    width: 100%;
    height: auto;

    .filterTable__loader {
        width: 100%;
        height: 300px;
        background: #999999;
        animation: 1.5s ease infinite loading-spinner;

        @keyframes loading-spinner {
            0% {
                opacity: 100%;
            }
            50% {
                opacity: 60%;
            }
            100% {
                opacity: 100%;
            }
        }
    }
}



