html, body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: "PT Sans", sans-serif;
    display: block;
    flex-direction: column;
}

#root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}

h1 {
    font-weight: 500;
}

/* COMMON Styles*/

.push-right{
    margin-left: auto !important;
}